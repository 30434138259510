import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/SEO/seo"
import Blurb from "../components/Blurb/blurb"
import { errorData } from "../siteData/data"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Blurb title={errorData.title} text={errorData.text} />
  </Layout>
)

export default NotFoundPage
